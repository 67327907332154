import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { OPTION_GROUP_VARIANTS } from 'dpl/quiz/option_group/utils/constants';

const OPTION_VARIANT_CLASSES = {
  [OPTION_GROUP_VARIANTS.CIRCLE]: 'QuizOptionGroupCircleOption__option br-100',
  [OPTION_GROUP_VARIANTS.RECTANGLE]:
    'QuizOptionGroupRectangleOption__option br1'
};

const OPTION_DETAILS_VARIANT_CLASSES = {
  [OPTION_GROUP_VARIANTS.CIRCLE]:
    'QuizOptionGroupCircleOption__optionDetails pv5 ph6',
  [OPTION_GROUP_VARIANTS.RECTANGLE]:
    'QuizOptionGroupRectangleOption__optionDetails pv3 ph5'
};
export default function DefaultOption({
  variant,
  value,
  onSelect,
  type,
  inputName,
  isSelected,
  title,
  subtitle
}) {
  const htmlId = `${inputName}-${value}`;
  const isCircular = variant === OPTION_GROUP_VARIANTS.CIRCLE;

  return (
    <div
      className={classnames(
        'QuizOptionGroupDefaultOption mh1 relative f0 ba bg-white',
        {
          'QuizOptionGroupCircleOption br-100 mb3': isCircular,
          'QuizOptionGroupRectangleOption br3 mb6': !isCircular,
          'b--gray-300 tertiary': !isSelected,
          'b--midnight midnight': isSelected
        }
      )}
    >
      <input
        checked={isSelected}
        className={classnames(
          'QuizOptionGroupDefaultOption__option Checkbox__input--custom no-focus-border transition',
          OPTION_VARIANT_CLASSES[variant],
          {
            'QuizOptionGroupDefaultOption__option--selected': isSelected
          }
        )}
        id={htmlId}
        name={inputName}
        onClick={onSelect}
        onChange={() => {}}
        type={type}
        value={value}
      />
      <label
        className={classnames('transform-center-all', {
          QuizOptionGroupCircleOption__label: isCircular
        })}
        htmlFor={htmlId}
      >
        <div className={OPTION_DETAILS_VARIANT_CLASSES[variant]}>
          <p className="font-16 fw-medium">{title}</p>
          <p className="font-16">{subtitle}</p>
        </div>
      </label>
    </div>
  );
}

DefaultOption.propTypes = {
  variant: PropTypes.oneOf([
    OPTION_GROUP_VARIANTS.CIRCLE,
    OPTION_GROUP_VARIANTS.RECTANGLE
  ]).isRequired,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  inputName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool
};

DefaultOption.defaultProps = {
  type: 'radio',
  subtitle: null,
  isSelected: false
};
