import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { groupBy } from 'dpl/shared/utils';

// HACK: Firefox doesn't handle SVG styles on def elements,
// so this just toggles the mask rect attributes
// to get a smooth transition when we can
// (COME ON, FIREFOX.  This even works on mobile Safari D:)
const isFirefox = /firefox/.test(window.navigator.userAgent.toLowerCase());
export default function FullPageQuizProgressBar({
  currentStep,
  steps,
  progressBarColor
}) {
  const groupedQuestions = groupBy(steps, 'sectionName');

  const numSections = Object.keys(groupedQuestions).length;
  let stepOffset = 0;

  return (
    <div className="FullPageQuizProgressBar">
      <div className="flex items-center">
        {Object.entries(groupedQuestions).map(([title, sectionSteps], idx) => {
          const titleText = `${idx + 1}: ${title}`;
          const stepCount = sectionSteps.length;
          const sectionWidth = 100 / numSections;
          const maskId = `colorSwap-${idx}`;
          const currentStepSectionName = steps[currentStep]?.sectionName;
          // only show if the current step has a sectionName and when in or past section
          const shouldShowTitle =
            currentStepSectionName && currentStep >= stepOffset;
          let maskX;
          stepOffset += stepCount;
          if (currentStep > stepOffset) {
            maskX = 0;
          } else {
            maskX = ((currentStep - stepOffset + 1) / stepCount) * 100;
          }

          const maskAttributes = isFirefox
            ? { width: `${100 + maskX}%` }
            : {
                width: '100%',
                style: { transform: `translateX(${maskX}%)` }
              };

          return (
            <div
              className={classnames(
                'FullPageQuizProgressBar__sectionWrapper transition bl-l b--white',
                { bw1: idx > 0 }
              )}
              key={title}
              style={{ width: `${sectionWidth}%` }}
            >
              <svg
                className={`FullPageQuizProgressBar__section w-100 h-100 relative f2 ${progressBarColor}`}
              >
                <defs>
                  <mask id={maskId}>
                    <rect
                      className="transition"
                      fill="white"
                      height="100%"
                      x="0"
                      y="0"
                      {...maskAttributes}
                    />
                  </mask>
                </defs>
                <rect width="100%" height="100%" fill="white" />
                <g fill="currentColor">
                  {shouldShowTitle && (
                    <text
                      className="d-none d-md-block"
                      textAnchor="start"
                      x="4"
                      y="16"
                    >
                      {titleText}
                    </text>
                  )}
                </g>
                <g mask={`url(#${maskId})`}>
                  <rect width="100%" height="100%" fill="currentColor" />
                  <g fill="white">
                    {shouldShowTitle && (
                      <text
                        className="d-none d-md-block"
                        textAnchor="start"
                        x="4"
                        y="16"
                      >
                        {titleText}
                      </text>
                    )}
                  </g>
                </g>
              </svg>
            </div>
          );
        })}
      </div>
    </div>
  );
}

FullPageQuizProgressBar.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      sectionName: PropTypes.string
    })
  ),
  progressBarColor: PropTypes.string
};

FullPageQuizProgressBar.defaultProps = {
  currentStep: 1,
  steps: [],
  progressBarColor: 'purple'
};
