import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

export default function FullPageQuizMobileNavigation({
  onPreviousClick,
  onNextClick,
  onSubmit,
  isPreviousDisabled,
  isNextDisabled,
  isSubmitDisabled,
  isLastStep,
  shouldShowSubmit,
  onBeforeNext
}) {
  async function handleNextClick() {
    if (onBeforeNext) {
      await onBeforeNext();
    }

    onNextClick();
  }

  return (
    <div className="FullPageQuizMobileNavigation flex primary pv3 ph2">
      <div className={classnames({ 'w-100': !isLastStep })}>
        <button
          type="button"
          onClick={onPreviousClick}
          disabled={isPreviousDisabled}
          className={classnames('inline-flex items-center f3 fw-medium', {
            'light-gray': isPreviousDisabled
          })}
        >
          <div
            className={classnames('button button--primary ph2 mr4', {
              'button--disabled': isPreviousDisabled
            })}
          >
            <Icon name="lined-arrow-left" width="30px" height="30px" />
          </div>
          {!isLastStep && 'Back'}
        </button>
      </div>

      {isLastStep && shouldShowSubmit && (
        <button
          type="button"
          onClick={onSubmit}
          className="button button--primary nowrap flex-auto f3 fw-medium w-100"
          disabled={isSubmitDisabled}
        >
          Submit your info
        </button>
      )}

      <div
        className={classnames('tr bl b--light-gray', {
          'o-0': isLastStep,
          'w-100': !isLastStep
        })}
      >
        <button
          type="button"
          onClick={handleNextClick}
          disabled={isNextDisabled || isLastStep}
          className={classnames('inline-flex items-center f3 fw-medium', {
            'light-gray': isNextDisabled
          })}
        >
          {!isLastStep && 'Next'}
          <div
            className={classnames('button button--primary ph2 ml4', {
              'button--disabled': isNextDisabled || isLastStep
            })}
          >
            <Icon name="lined-arrow-right" width="30px" height="30px " />
          </div>
        </button>
      </div>
    </div>
  );
}

FullPageQuizMobileNavigation.propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPreviousDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  shouldShowSubmit: PropTypes.bool,
  onBeforeNext: PropTypes.func
};

FullPageQuizMobileNavigation.defaultProps = {
  shouldShowSubmit: true,
  onBeforeNext: null
};
