import { addQueryToUrl } from 'dpl/util/queryString';

/**
 * This is a bit of a bloated function, but it's intended to
 * consolidate new uploads + croppie results for croppable photos.
 * It does the following:
 *   - takes in the new upload instance
 *   - compares it to the lastest upload instance (if any)
 *   - saves a 'temporary photo' in currentPhotograph for display purposes
 *   - parses croppie points (if any)
 *   - returns a new object that holds the new photograph state
 */
export function getCroppablePhotoState({
  currentPhotograph = {},
  uploadInstance,
  croppieInstance
}) {
  if (Array.isArray(uploadInstance)) {
    [uploadInstance] = uploadInstance;
  }

  const newState = {
    currentPhotograph: { ...currentPhotograph }
  };

  if (uploadInstance) {
    // don't want to override the old photograph attributes
    newState.currentPhotograph = {
      file: uploadInstance.blob.signed_id
    };
    newState.latestUploadInstance = uploadInstance;
  }

  if (
    croppieInstance &&
    croppieInstance.points &&
    croppieInstance.points.length
  ) {
    const [minX, minY, maxX, maxY] = croppieInstance.points;
    newState.currentPhotograph = {
      ...newState.currentPhotograph,
      crop_min_x: minX,
      crop_min_y: minY,
      crop_max_x: maxX,
      crop_max_y: maxY
    };
  }

  return newState;
}

// photograph might be an empty object or an object with null url fields
// require photograph.original_url since that's what we use
// as the basis for cropping/rotating in the Image and Overlay components
export function isValidPhotograph(photograph) {
  return Boolean(photograph && photograph.original_url);
}

// return the photograph.original_url modified with crop/rotate query parameters
// this is used to consolidate the display url for persisted and non-persisted photographs
export function getDisplayUrl(photograph) {
  if (!isValidPhotograph(photograph)) {
    return null;
  }

  const {
    crop_max_x: maxX,
    crop_max_y: maxY,
    crop_min_x: minX,
    crop_min_y: minY,
    rotate,
    original_url: originalUrl
  } = photograph;
  const hasMinX = typeof minX === 'string' || typeof minX === 'number';
  const hasRotate = typeof rotate === 'number';

  let displayUrl = originalUrl;

  if (hasMinX || hasRotate) {
    displayUrl = addQueryToUrl(
      {
        ...(hasMinX && { crop: `${minX}-${minY}x${maxX}-${maxY}` }),
        ...(hasRotate && { rotate }),
        type: 'manual'
      },
      displayUrl
    );
  }

  return displayUrl;
}
