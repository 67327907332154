/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CroppablePhotographPropTypes } from 'dpl/shared/utils/customPropTypes';
import { preloadImage } from 'dpl/shared/utils';
import NullEB from 'dpl/components/NullEB';
import ImageUpload from 'dpl/components/Upload/ImageUpload';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import useUploadCapabilities from 'dpl/shared/hooks/useUploadCapabilities';

import { VIEWPORT_TYPES } from '../utils/constants';
import { getDisplayUrl, isValidPhotograph } from '../utils';
import CroppableOverlayAsync from './OverlayAsync.next';

export default function Image({
  alt,
  children,
  className,
  imageUploadClassName,
  inputName,
  onSave,
  onUploadStart,
  imageFailedMessage,
  openCropOverlayOnUpload,
  photograph,
  round,
  title,
  viewportRatio,
  disabled
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentPhotograph, setCurrentPhotograph] = useState(null);

  function handleUploadSuccess([uploadInstance]) {
    onSave({
      file: uploadInstance.blob.signed_id,
      // preserve scrappy url for non-persisted records
      original_url: uploadInstance.blob.scrappy_url
    });

    if (openCropOverlayOnUpload) {
      setIsOverlayOpen(true);
    }
  }

  const { pendingUploads, uploadFiles } =
    useUploadCapabilities(handleUploadSuccess);

  const isUploading = pendingUploads.length > 0;

  function handleSave(croppedPhotograph) {
    setCurrentPhotograph(croppedPhotograph);

    onSave(croppedPhotograph);
  }

  useEffect(() => {
    if (isUploading) onUploadStart?.();
  }, [isUploading]);

  useEffect(() => {
    if (
      isValidPhotograph(photograph) &&
      !isValidPhotograph(currentPhotograph)
    ) {
      setCurrentPhotograph(photograph);
    } else if (isValidPhotograph(currentPhotograph)) {
      setIsLoading(true);
      preloadImage(getDisplayUrl(currentPhotograph))
        .catch(() => {
          /* carry on */
        })
        .then(() => setIsLoading(false));
    }
  }, [photograph, currentPhotograph]);

  const displayUrl = getDisplayUrl(currentPhotograph);

  return (
    <div
      className={classnames(
        'CroppableImage relative hide-child dib f0 h-100 w-100',
        className
      )}
    >
      {displayUrl ? (
        <div>
          <LoadingWrapper
            className="transform-center-all"
            isLoading={isLoading}
          >
            <div
              className={classnames(
                'CroppableImage__thumbnail h-100 w-100 absolute left-0 top-0 cover bg-center',
                {
                  'br-100': round
                }
              )}
              data-test-id="CroppableImage__thumbnail"
              style={{ backgroundImage: `url(${displayUrl})` }}
            />
            <NullEB>
              <a
                className={classnames(
                  'pointer child bg-black-40 white w-100 h-100 transform-center-all flex align-items-center justify-center f3',
                  {
                    'br-100': round
                  }
                )}
                onClick={e => {
                  e.preventDefault();
                  setIsOverlayOpen(true);
                }}
                role="button"
              >
                Edit
              </a>
              {isOverlayOpen && (
                <CroppableOverlayAsync
                  alt={alt}
                  photograph={photograph}
                  inputName={inputName}
                  onSave={handleSave}
                  imageFailedMessage={imageFailedMessage}
                  onClose={() => setIsOverlayOpen(false)}
                  viewportRatio={viewportRatio}
                  viewportType={
                    round ? VIEWPORT_TYPES.CIRCLE : VIEWPORT_TYPES.SQUARE
                  }
                />
              )}
            </NullEB>
          </LoadingWrapper>
        </div>
      ) : (
        <ImageUpload
          className={classnames(
            'left-0 top-0 w-100 h-100',
            imageUploadClassName,
            {
              'br-100': round
            }
          )}
          name={inputName}
          onChange={e => uploadFiles(e.target.files)}
          loading={isLoading || pendingUploads.length > 0}
          title={title}
          disabled={disabled}
        >
          {children}
        </ImageUpload>
      )}
    </div>
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  imageUploadClassName: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  imageFailedMessage: PropTypes.string,
  openCropOverlayOnUpload: PropTypes.bool,
  photograph: PropTypes.shape(CroppablePhotographPropTypes),
  round: PropTypes.bool,
  title: PropTypes.node,
  viewportRatio: PropTypes.number,
  disabled: PropTypes.bool
};

Image.defaultProps = {
  alt: '',
  children: null,
  className: '',
  imageUploadClassName: null,
  imageFailedMessage: '',
  openCropOverlayOnUpload: false,
  photograph: {},
  round: true,
  title: undefined,
  viewportRatio: null,
  onUploadStart: null,
  disabled: false
};
