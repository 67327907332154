import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'dpl/components/Logo';
import Icon from 'dpl/common/components/Icon';
import { isNativeAppUA } from 'dpl/shared/utils';

const IS_NATIVE_UA = isNativeAppUA();

export default function FullPageQuizHeader({
  children,
  onSaveClick,
  onBackClick,
  closeButtonLabel
}) {
  return (
    <div className="FullPageQuizHeader ph6 pv4">
      <div className="row no-gutters items-center">
        <div className="col-2">
          {onBackClick && (
            <button
              className="flex items-center black-50 transition hover-purple f2"
              onClick={onBackClick}
              type="button"
            >
              <Icon
                className="FullPageQuizHeader__icon mr2"
                name="arrow-left"
              />
              <span className="d-none d-sm-block">Back</span>
            </button>
          )}
        </div>
        <div className="col">
          <div className="Header__logo purple center">
            <Logo />
          </div>
        </div>
        <div className="col-2">
          {!IS_NATIVE_UA && (
            <button
              type="button"
              className="ml-auto flex items-center black-50 transition hover-purple f2"
              onClick={onSaveClick}
            >
              <span className="d-none d-md-block nowrap">
                {closeButtonLabel}
              </span>
              <span className="FullPageQuizHeader__icon ml3">
                <Icon name="close" />
              </span>
            </button>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

FullPageQuizHeader.propTypes = {
  children: PropTypes.node,
  onSaveClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  closeButtonLabel: PropTypes.string
};

FullPageQuizHeader.defaultProps = {
  children: null,
  onBackClick: null,
  closeButtonLabel: 'Save & Exit'
};
